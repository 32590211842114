<!-- 价格说明页面 -->
<template>
    <div class='explain'>
        <van-loading v-if="loading" style="position: absolute;left: 50%;transform: translateX(-50%);top: 50%;" size="42"
            type="spinner" color="#D6562E" />
        <div :class="!show ? 'zx' : ''" class="header">
            <svg-icon @click="back" class="svg" iconClass="right"></svg-icon>
        </div>
        <div class="lineBox">
            <Ueditor v-show="show" @ueditorChange="loading = false" class="myUeditor" ref="ueditor" :readonly="true"
                :autoSyncData="false" :bar="bars" :enableContextMenu="false"></Ueditor>
        </div>
    </div>
</template>

<script>
import Ueditor from "@/components/Editor/Ueditor.vue";
import PlanApi from "@/components/Api/planApi.js"
import { Notify } from "vant";
import router from '@/router'
import { mapMutations, mapState } from "vuex";

export default {
    components: { Ueditor },
    data() {
        return {
            bars: [],
            show: false,
            loading: true,
            previewDatas: [], //渲染的数据
        }
    },
    computed: {
        ...mapState('collection', ['programmeId']),
    },
    watch: {},
    methods: {
        //返回上一个页面
        back() {
            router.go(-1)
        },
        init() {
            let obj = {
                programmeId: this.programmeId
            }
            new PlanApi("plan").queryByCompanyId(obj).then((result) => {
                if (result.status === 200) {
                    console.log(result.data, '看看数据9999');
                    let datas = result.data.htmlContentStr
                    if (datas !== '') {
                        this.show = true
                        this.$nextTick(() => {
                            this.$refs.ueditor.setUeContent(datas)
                        })
                    } else {
                        this.loading = false
                        Notify({ type: 'warning', message: '暂无数据' });
                    }

                }
            }).catch((err) => {
                Notify({ type: 'danger', message: '系统异常！' });
            });
        },
    },
    created() {

    },
    mounted() {
        this.init()
    },
}
</script>
<style lang='scss' scoped>
/deep/ .edui-editor-toolbarbox .edui-default {
    display: none;
}
.myUeditor {
    top: 4px;

    /deep/.edui-editor {
        border: none;
    }

    /deep/.edui-editor-bottomContainer {
        display: none;
    }

    /deep/ .firstRow {
        background-color: red;
    }
}

.zx {
    z-index: 1999 !important;
}

.explain {
    position: relative;
    .header {
        padding-left: 0.3rem;
        z-index: 8888;
        position: sticky;
        top: 0;
        height: 0.7rem;
        background-color: #fff;
        display: flex;
        align-items: center;

        .svg {
            transform: rotate(180deg);
            height: 0.45rem;
            width: 0.45rem;
        }
    }


    .lineBox {
        white-space: pre-line;

        .p1 {
            font-size: 0.35rem;
            color: #285ca2;
            font-weight: 700;
        }

        .p2 {
            margin-bottom: 0.1rem;
            white-space: pre-line;
        }
    }
}
</style>
