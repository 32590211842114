var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "explain" },
    [
      _vm.loading
        ? _c("van-loading", {
            staticStyle: {
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: "50%",
            },
            attrs: { size: "42", type: "spinner", color: "#D6562E" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "header", class: !_vm.show ? "zx" : "" },
        [
          _c("svg-icon", {
            staticClass: "svg",
            attrs: { iconClass: "right" },
            on: { click: _vm.back },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "lineBox" },
        [
          _c("Ueditor", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            ref: "ueditor",
            staticClass: "myUeditor",
            attrs: {
              readonly: true,
              autoSyncData: false,
              bar: _vm.bars,
              enableContextMenu: false,
            },
            on: {
              ueditorChange: function ($event) {
                _vm.loading = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }