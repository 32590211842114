var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mod-demo-ueditor" },
    [
      _c("vue-ueditor-wrap", {
        staticClass: "ueditor-box",
        attrs: { config: _vm.myConfig },
        model: {
          value: _vm.ueContent,
          callback: function ($$v) {
            _vm.ueContent = $$v
          },
          expression: "ueContent",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }